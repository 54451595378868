import React from "react"
import "../styles/styles.scss"
import LsPageComponent from "../components/lsPageComponent"
import { Helmet } from "react-helmet"

const LSPage = () => (
  
  <div>
  <Helmet
    title="Security-Alarm-KMU" defer={false}>
      <html lang="de" />
      <meta name="description" content="ALARM-Projekt -- Security Game 01 -- Der erste Tag" />
      <meta name="keywords" content="Game, Security, ALARM, Awareness, KMU" />
  </Helmet>
    <LsPageComponent
      ls="1" 
      game="ls1"
      test="true"
      questionairs="false"
      motto="Der erste Tag"
    chapterUrl="https://client.gamebook.io/alarm-stories/live/index.html?episode=https://exg-gb-beta.s3.eu-west-1.amazonaws.com/file-uploads/exports/5fb6caf76b2e2100133d4dc5/5fcf7ac16531730013c8be2e/Chapter_01_110.zip"/>
  </div>
)

export default LSPage
